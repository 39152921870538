
export default {
  name: 'AtomBulletSpecs',
  props: {
    id: {
      required: false,
      type: String,
      default: ''
    },
    showTop: {
      required: false,
      type: String,
      default: ''
    },
    showBottom: {
      required: false,
      type: String,
      default: ''
    },
    showLeft: {
      required: false,
      type: String,
      default: ''
    },
    showRight: {
      required: false,
      type: String,
      default: ''
    },
    showCenter: {
      required: false,
      type: String,
      default: ''
    },
    text: {
      required: false,
      type: String,
      default: ''
    },
    image: {
      required: false,
      type: String,
      default: ''
    },
    category: {
      required: false,
      type: String,
      default: ''
    },
    topPostion: {
      required: false,
      type: String,
      default: 'initial'
    },
    rightPostion: {
      required: false,
      type: String,
      default: 'initial'
    },
    bottomPostion: {
      required: false,
      type: String,
      default: 'initial'
    },
    leftPostion: {
      required: false,
      type: String,
      default: 'initial'
    },
    activeBullet: {
      required: true,
      type: String,
      default: 'item-0'
    },
    bulletId: {
      required: true,
      type: String,
      default: ''
    }
  },
  computed: {
    isActive() {
      return this.$props.activeBullet === this.bulletId
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters.cdnBase}/${url}`
    },
    handleClick() {
      this.$emit('change', this.bulletId)
    }
  }
}
